.stream-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
}

/* handles the 16:9 aspect ratio */
.aspect-16x9 {
  //   position: relative;
  //  padding-top: 56.25%;
  height: 0;
  overflow: hidden;
  border-radius: var(--border-radius);
}

.player {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.player:hover .player-ui-controls {
  z-index: 1;
}

.video-el {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: black;
}

.player-ui {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
}

.player-ui-captions {
  padding: 5px;
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: center;
}

.player-ui-captions__text {
  padding: 7px 9px;
  width: 100%;
  max-width: 290px;
  background-color: #000;
  font-size: 16px;
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.player-ui-controls {
  padding: 10px 10px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  display: flex;
  flex-direction: row;
  //   z-index: -1;
  //  border:1px solid red;
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
  opacity: 0.5;
  //visibility: hidden;
  //pointer-events: none;
}

.player-ui-controls:hover {
  opacity: 1;
  //visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.player-ui-controls__actions {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.player-ui-controls__actions--right {
  justify-content: flex-end;
}

.player-ui-button {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  color: white;
  border: none;
  background: transparent;
  &:hover {
    color: #bd1c3d;
  }
}

@media (max-width: 767px) {
  .player-ui-captions__text {
    font-size: 12px;
  }

  .player-ui-button {
    margin: 0;
    padding: 5px;
  }
}

.player-debug {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 5px;
  z-index: 2;
}

.debug-info-table tbody tr th {
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}

.player-settings {
  min-width: 300px;
  margin: auto;
  padding: 5px;
  background-color: white;
  text-align: center;
  border-radius: 5px;
  overflow: auto;
  z-index: 6;
}

.player-settings h3 {
  padding-bottom: 10px;
}

.player-settings h3,
.player-settings h4,
.player-settings .close-icon {
  color: #eb5f07;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.placeholder {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.placeholder-content {
  position: relative;
}

.placeholder-spinner {
  position: relative;
  animation: rotate 1s linear infinite;
  border-radius: 50%;
  height: 38px;
  width: 38px;
}

.placeholder-spinner:after {
  content: "";
  position: absolute;
  background: #000;
  border-radius: 50%;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
}

.placeholder-gradient {
  position: absolute;
  border-radius: 50%;
  background-position: 0% 0%, 100% 0%, 100% 100%, 0% 100%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.placeholder-message {
  color: #fff;
}

.player-settings label {
  display: block;
  padding: 5px;
}

.close-icon {
  float: right;
}

.join-stream-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 32px;
  @media screen and (max-width: 600px) {
    padding-bottom: 16px;
  }
}

.MuiDialogTitle-root.join-stream-modal-title {
  padding: 32px 72px;
  @media screen and (max-width: 600px) {
    padding: 16px 48px;
  }
}

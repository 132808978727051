.col-flexbox-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
}
.col-flexbox-component {
  padding: 10px;
  //border: 3px solid #333;
}

.row-flexbox-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  //border: 3px solid #333;
  /*
  @media screen and (max-width: 600px) {
    display: block;
  }
  */
}
.row-flexbox-component {  
  //padding: 5px;
  display: flex;
  //border: 3px solid #333;
  @media screen and (max-width: 600px) {
    padding: 5px;
  }
}

.total-wrapper {
  //display: grid;
  //grid-template-columns: 0.5fr 2fr;
  font-weight: bold;
}
.cartIcon {
  grid-column: 1/2;
  grid-row: 1/3;
}

.totalButton:hover .cartIcon {
  filter: invert(0.7);
}

/*.totalLabel{
    grid-column: 2/3;
    grid-row: 1/2;
    margin-right: 0.5rem;
}*/
.total {
  text-align: right;
  //grid-column: 2/3;
  //grid-row: 2/3;
  //margin-right: 0.5rem;
}
.donationCartTotal {
  font-size: 0.9rem;
  font-weight: bold;
}
.donateTitle {
  margin-block-start: 0.2rem;
  margin-block-end: 0.2rem;
  font-size: 1.25rem;
  font-weight: bold;
}
.creditCard {
  font-size: 1rem;
  font-weight: 400;
}
.creditCardNumber {
  font-size: 1rem;
  font-weight: bolder;
}
.checkOutTotal {
  font-size: 1rem;
  font-weight: 400;
}
.orderProcessingTitle {
  font-size: 1.5rem;
  font-weight: bolder;
  text-align: center;
}
.orderProcessingAmt {
  font-size: 1.17rem;
  font-weight: bold;
  text-align: center;
}
.orderProcessingPara {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

.thankYouTitle {
  font-size: 2rem;
  font-weight: bolder;
  text-align: center;
  margin-block-start: 1.5rem;
  margin-block-end: 1.5rem;
}
.thankYouPara {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-block-end: 2.5rem;
}
.cancel {
  fill: gray !important;
  font-size: 2rem !important;
}

.in-event-donation-popper-paper {
  height: fit-content;
  @media screen and (min-width: 601px) {
    min-height: 231px;
    min-width: 590px;
    padding: 16px 16px 16px 16px;
  }

  @media screen and (max-width: 600px) {
    min-height: 277px;
    min-width: 150px;
    //max-width: 370px;
    padding: 5px 5px 5px 5px;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    //width: fit-content;
    display: flex;
    flex-direction: column;
  }
}

.addDonationButtonGridItem {
  display: flex;
}

.donationCartTotalWrapper {
  align-items: center;
  display: flex;
  @media screen and (max-width: 600px) {
    max-width: 100%;
    flex-basis: 100%;
    justify-content: flex-end;
  }
}

.col-flexbox-component.bottom-buttons-wrapper{
    padding-top: 15px;   
}

.row-flexbox-container.donationCartTotal {
  flex-wrap: wrap;
  @media screen and (max-width: 600px) {
    display: block;
  }
}

.row-flexbox-container.otherTextFieldContainer {
  flex-wrap: wrap;
}

.otherTextFieldWrapper {
  @media screen and (max-width: 600px) {
    max-width: 75%;
    flex-basis: 100%;
    justify-content: stretch;
  }
}

.otherTextField {
  width: 100%;
  @media screen and (min-width: 601px) {
    height: 48px;
  }
  .MuiFormHelperText-root {
    position: static;
    @media screen and (min-width: 601px) {
      position: absolute;
    }
  }
}

.otherButtonWrapper {
  @media screen and (max-width: 600px) {
    max-width: 25%;
    flex-basis: 100%;
    justify-content: stretch;
    max-height: 58px;
  }
}

.addDonationWrapper {
  @media screen and (max-width: 600px) {
    max-width: 100%;
    flex-basis: 100%;
    justify-content: stretch;
    //padding-top: 2.2rem;
  }
}

.donateButtonWrapper {
  display: flex;
  height: 100%;
  align-items: stretch;
}

.donatePopper {
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.donatePopper.amountWidget {
  @media only screen and (max-width: 600px) {
    max-height: 100%;
  }
}

.col-flexbox-container.donatePaperWrapper {
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.row-flexbox-container.checkout {
  @media screen and (max-width: 600px) {
    display: block;
  }
}

.donationAmountSection {
  overflow: auto;
  max-height: 128px;
}
.amountButtonGridItem {
  display: flex;
  justify-content: center;
}

.nav-links-mobile {
  .donatePopper {
    @media screen and (min-width: 601px) {
      display: none;
    }
  }
}

.nav-links{
    .donatePopper {
        @media screen and (max-width: 600px) {
          display: none;
        }
      }
}

#donate-popper {
  button.btn-close,
  .MuiFab-root {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    width: 42px;
    height: 42px;
    box-shadow: none;
  }
  .MuiFab-root:active {
    box-shadow: none;
  }

  // close btn at the top left of the module
  button.left-btn-close {
    position: absolute;
    top: -1.5rem;
    left: -1.5rem;
  }
} // close btn at the t
/* Chat Styling */

.input__sticky {
  // sets the emoji and live reaction buttons to the right
  justify-content: flex-end !important;
}

.button__emoji {
  // shrinks the emoji button to fit
  img {
    width: 20px;
  }
}



.button__reactions {
  // shrinks the live reaction button to fit
  img {
    width: 20px;
  }
}

.main__chat {
  // shrinks the live reaction images that appear when you click live reaction
  span.emoji {
    img {
      width: 20px;
    }
  }
}

.chat__thumbnail, .message__thumbnail, .message__timestamp, .message__date{
  // removes the icon/large letter from the header
  width: 0px !important;
  height: 0px !important;
  display: none;
  img {
    display: none;
  }
}

.message__timestamp{
  display: none !important;
}

.receiver__message__container{
  margin: 4px 0px !important;
  .message__txt__wrapper, .message__name__wrapper{
    padding: 0px !important;
  }
}
.sender__message__container .message__name{
  color: blue;
}
.emoji{
  padding-bottom: 6px;
}

.chat__details {
  // increases the width of the header
  width: 100% !important;
}


.chat__user {
  // puts the chat room name and number of members on the same row and sepeartes them
  flex-direction: row !important;
  justify-content: space-between;
}

.user__status {
  // aligns the number of memebers text to the right
  text-align: end;
}

.chat__options {
  // effectively removes the div for the call buttons (which aren't being displayed)
  width: 0px !important;
}
.MuiSvgIcon-root.chat-expand-icon{
  @media screen and (min-width: 601px) {
    display: none;
  }
}
.agent-color .sender__message__container .message__name{
  color: #D11947;
}
.agent-color .message__text__container .message__txt__wrapper{
  color: #D11947;
}
.agent-color .receiver__message__container .message__name{
  color: #D11947;
}
.agent-color .receiver__message__container .message__action__wrapper .message__txt__wrapper{
  color: #D11947;
}
.message__txt__wrapper .message__txt{
  display: inline;
}
.confetti-wrapper{
  overflow: visible;
}
.confetti-wrapper > div{
  position: absolute;
  bottom: 0;
  right: 0;
}
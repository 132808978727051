/* Sequin Detail */
.sequin-view {
  position: absolute;
  right: -7.5rem;
  top: 4rem;
  transform: rotate(20deg);
  @media screen and (max-width: 600px) {
    right: -0.5rem;
  }
  svg {
    height: 13rem;
    width: 13rem;
    fill: blue;
    @media screen and (max-width: 600px) {
      height: 7rem;
      width: 7rem;
    }
  }

  span {
    display: none;
  }
}

.sequin-review {
  float: left;
  display: block;
  margin: 0.3rem;
  transform: rotate(20deg);
  svg {
    height: 10rem;
    width: 10rem;
    fill: #ff079f;
  }
}

.sequin-content-checkout {
  grid-column: 1 / span 2;
  text-align: center;
  overflow: auto;
  @include module_scroll;
}

.MuiGrid-root.sequin-owner{
  max-width: 82.5%;
}

.sequin-owner {
  padding-bottom: 0px;
  max-width: 84%;
  h2 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    max-width: 280px;
    display: block;
  }
  h3 {
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.MuiGrid-root.sequin-detail p {
  max-width: 280px;
  display: block;
  min-width: 80%;
}

.MuiGrid-root.sequin-detail {
  padding-top: 0px;
  margin-bottom: 10px;
  max-width: 82.5%;
  min-width: 80%;
}

.sequin-social {
  margin-top: 15px;
  button {
    @media screen and (max-width: 600px) {
      margin-right: 0.6rem;
    }
  }
}

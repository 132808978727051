/* Countdown Timer */

.countdown-container {
  display: flex;
  flex-direction: column;

  .countdown-header {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: -1px;
    color: white;
    text-align: center;
    @media screen and (max-width: 701px) {
      font-size: 1.75rem;
    }
  }
}

.countdown-timer{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.countdown-time {
  height: 6.75rem;
  width: 6.75rem;
  padding: 1rem;
  margin: 0.25rem;
  border-radius: 50%;
  border: 4px solid black;
  background-color: black;
  text-align: center;

  @media screen and (max-width: 701px) {
    border: 2px solid black;
    height: 5rem;
    width: 5rem;
    margin: .1rem;
  }

  .countdown-count{
    font-size: 3rem;
    font-weight: bold;
    color: white;
    line-height: 3rem;

    @media screen and (max-width: 701px) {
      font-size: 2rem;
      line-height: 1.9rem;

    }
  }

  .countdown-label{
    font-size: .75rem;
    color: gray;
  }
}

// Polling Module

.poll_wrapper {
  @include module_wrapper;
  max-height: 70vh;
  overflow-y: scroll;
}

.MuiContainer-root.poll_container {
  @include module_container;
  z-index: 10;
  max-width: 800px;
  &.winner {
    max-width: 600px;
  }
}

@media (max-width: 800px) {
  .MuiContainer-root.poll_container {
    top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .poll_wrapper {
    max-height: 90vh;
    height: 90vh;
  }
  .poll_choice_wrapper {
    flex-direction: column;
  }
  .poll_choice_container {
    flex-basis: 1;
  }
}

@media (min-width: 801px) {
  .poll_choice_container:nth-child(2n) .poll_choice {
    margin-left: 5px;
  }

  .poll_choice_container:nth-child(2n - 1) .poll_choice {
    margin-right: 5px;
  }
}

.poll_title {
  @include module_title;
}

.poll_description {
  @include module_description;
}

.poll_choice_container {
  margin-bottom: 10px;
  flex-basis: 50%;
}

.poll_header {
  margin-bottom: 5px;
}

.poll_caption {
  font-weight: 700;
  font-size: 21px;
  padding-bottom: 15px;
  max-width: 50%;
}

.poll_countdown {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
}

.poll_countdown_time {
  font-weight: 800;
  font-size: 18px;
}

.poll_choice {
  position: relative;
  overflow: hidden;
  display: flex;
  &.selected {
    outline-style: solid;
    outline-color: #1874dc;
    outline-width: thick;
    opacity: 1;
  }
  &.unselected {
    .poll_choice_img {
      opacity: 0.5;
    }
    &.hovered {
      .poll_choice_title {
        display: flex;
      }
    }
  }
  &.preselect {
    &.hovered {
      .poll_choice_img {
        opacity: 0.5;
      }
      .poll_choice_title {
        display: flex;
      }
    }
  }
}

.poll_choice_img {
  width: 100%;
  height: 100%;
}

.poll_choice_overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.poll_choice_title {
  flex-grow: 1;
  font-size: 1.2rem;
  font-weight: 900;
  display: none;
  align-items: center;
  text-align: center;
  width: 45%;
  span {
    width: 100%;
  }
}

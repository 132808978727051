// ProgressBar

%progress_bar {
  height: 3.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress_bar_back {
  @extend %progress_bar;
  position: relative;
  text-align: center;
  font-size: 1rem;
  font-weight: 900;
  width: 100%;
  background: rgba(255, 255, 255, 0.65);
  color: rgba(0, 0, 0, 0.87);
}

.progress_bar_front {
  @extend %progress_bar;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #1874dc;
  color: white;
}

.progress_bar_bound_box {
  @extend %progress_bar;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}

@media screen and (min-width: 1000px) {
  .progress_bar_back {
    font-size: 19.2px;
  }
}

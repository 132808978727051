.MuiTabs-root.account-tabs-horizontal{
  //padding-top: 5rem;
  border-right: 1px solid #eee;

  .Mui-selected span{
    font-weight: bolder;
  }

  @media screen and (min-width: 601px){
    display: none;
  }
}
// Vertical Tabs for Account Management ETC.

.MuiTabs-root.account-tabs{
  min-height: 600px;
  padding-top: 1rem;
  border-right: 1px solid #eee;

  .Mui-selected span{
    font-weight: bolder;
  }
  //width: 20%;
  @media screen and (max-width: 1280px) {
    width: 35%;
  }
  @media screen and (max-width: 960px) {
    width: 30%;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.tab-panel{
  height: auto;
  width: 80%;
  @media screen and (max-width: 1280px) {
    width: 65%;
  }
  @media screen and (max-width: 960px) {
    width: 60%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
}

.MuiTabs-indicator{
  left: 0;
  right: inherit;
}

.MuiTabs-vertical .MuiTab-wrapper{
  flex-direction: row;
  justify-content: flex-start;
  padding-left: .5rem;
}
/* Landing Page */

.home-container {
  position: relative;
  top: 0;
  right: 0;
  bottom:0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5rem;

  .top{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 75%;

    @media screen and (max-width: 769px) {
      flex-direction: column;
      align-items: center;
      height: 60%;
    }

  }

  .btn-mid-row{
    padding-top: 1rem;
  }

  .mid-row{
    @media screen and (min-width: 1921px) {
      width: 30%;
    }
    @media screen and (min-width: 1421px) and (max-width: 1920px){
      width: 40%;
    }
    @media screen and (min-width: 1221px) and (max-width: 1420px){
      width: 50%;
    }
    @media screen and (min-width: 1121px) and (max-width: 1220px){
      width: 55%;
    }
    @media screen and (min-width: 1021px) and (max-width: 1120px){
      width: 60%;
    }
    @media screen and (min-width: 921px) and (max-width: 1020px) {
      width: 65%;
    }
    @media screen and (min-width: 821px) and (max-width: 920px) {
      width: 75%;
    }
    @media screen and (min-width: 721px) and (max-width: 820px) {
      width: 90%;
    }
    @media screen and (min-width: 650px) and (max-width: 720px) {
      width: 100%;
    }
    @media screen and (max-width: 649px) {
      width: 100%;
    }
  }

  .bottom{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 25%;
    align-items: flex-end;

    @media screen and (max-width: 769px) {
      flex-direction: column;
      align-items: center;
      height: 40%;
    }

    .left{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;

      @media screen and (max-width: 769px) {
        justify-content: center;
      }
    }

    .right{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 100%;

      @media screen and (max-width: 769px) {
        justify-content: center;
      }
    }
  }

  .home-welcome-msg{
    font-size: 4rem;
    font-weight: bold;
    color: white;
  }

  // Sequin Button
  .btn-garden{
    font-size: 1.25rem;
    color: white;
    border-color: white;
    justify-content: center;
    padding: 1rem;
    line-height: normal;
    &:hover{
      background: white;
      color: black;
      border-color: black;
    }
  }
}

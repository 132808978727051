// Register Module

.register_wrapper {
  @include module_wrapper;
  &.left {
    border-radius: 4px 0px 0px 4px;
    flex-basis: 67%;
  }
  &.right {
    border-radius: 0px 4px 4px 0px;
    flex-basis: 33%;
  }
  &.shaded {
    background-color: #848484;
  }
}

.register_header {
  margin-bottom: 1rem;
}

.MuiContainer-root.register_container {
  @include module_container;
  max-height: 80%;
  overflow: auto;
  max-width: 40%;
  @media screen and (max-width: 900px) {
    max-width:70%;
  }
  @media screen and (max-width: 600px) {
    max-width:100%;
  }
}

.register_title {
  @include module_title;
  color: #212121;
}

.register_description {
  @include module_description;
}

.register_password {
  // max-width: 80%;
}

.register_donate_msg {
  border-top-style: solid;
  border-top-width: 2px;
  border-top-color: #707070;
  color: #707070;
  padding-top: 15px;
  padding-bottom: 25px;
  &.thanks {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #707070;
  }
}

// Page Background

.background-layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("https://sjexperience-media.s3.amazonaws.com/images/00201389-005-sequinwall-080818-medium.jpg")
    no-repeat center center fixed;
  background-size: cover;
  height: 100%;
  overflow: hidden;
  z-index: -10;
}

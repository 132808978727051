// RSVP Module

.MuiContainer-root.rsvp-wrapper {
  max-height: 80%;
  overflow: auto;
  max-width: 40%;
  @media screen and (max-width: 800px) {
    max-width:70%;
  }
  @media screen and (max-width: 600px) {
    max-width:100%;
  }
}

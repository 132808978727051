/* React Player Module */



.react-player {
    display: block;
    width: 100%;
}

.standard-react-player-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -5;
}

.standard-react-player {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;

  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  video {
    z-index: 1;

    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;

    max-width: 100%;

    @media screen and (min-width: 701px) {
      max-width: inherit;
    }

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
/* Site Header */
$headersHeight: 64px;
$mobileHeaderHeight: 40px;

.siteHeader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 7;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 2rem;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.75) -30%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}

.siteBrand {
  padding: 0;
  position: relative;
  display: inline-block;
  //pointer-events: auto;
  flex-grow: 1;
  pointer-events: none;
}

.MuiSvgIcon-fontSizeLarge.siteIcon {
  font-size: 4.5rem;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -1rem;
  left: -0.5rem;
  cursor: pointer;
  @media only screen and (max-width: 600px) {
    font-size: 3.25rem;
    top: -1.5rem;
    left: 0;
  }
}

// Site Name

.siteName {
  display: inline-block;
  margin-left: 4rem;
  color: #fff;
  font-size: 1.1rem;
  cursor: pointer;
  letter-spacing: -0.0162rem;
  line-height: 1.15rem;

  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.siteNameTop {
  display: block;
  font-weight: 800;
}

.app-bar.MuiAppBar-colorPrimary {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
}

.MuiAppBar-root a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
.app-bar {
  margin-right: 1em;
  display: flex;
  height: 100%;
}
.MuiToolbar-root.MuiToolbar-regular.nav-toolbar.MuiToolbar-gutters {
  //min-height: fit-content;
  height: 100%;
  @media only screen and (max-width: 600px) {
    //min-height: #{$mobileHeaderHeight};
  }
}
.app-bar .nav-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //height: #{$headersHeight};
  @media only screen and (max-width: 600px) {
    //height: #{$mobileHeaderHeight};
  }
  a {
    display: flex;
    flex-grow: 1;
  }
  .donate-btn {
    border-radius: 5px;
    margin-right: 0;
    padding: 0.5em 2.1em;
    display: flex;
    //height: 100%;
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  .ms-donate-btn {
    border-radius: 5px;
    margin-right: 0;
    padding: 0.5em 2.1em;
    display: flex;
  }
  .totalButton.live-btn {
    color: white;
    border: 0.5px solid;
    border: 0;
    border-radius: 0;
    border-color: white;
    &:hover {
      color: gray;
    }
    border-style: none;
    margin-right: 0rem;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    height: 100%;
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  .forest-btn.live-btn {
    border-radius: 0px;
    border-style: none;
    color: white;
    display: flex;
    height: 100%;
    &:hover {
      color: gray;
    }
  }
  .forest-btn {
    border-radius: 0px;
    border-style: none;
    margin-right: 0;
    color: white;
    padding-left: 24px;
    padding-right: 24px;
    &:hover {
      color: gray;
    }
  }
  .join-event {
    border-radius: 0px;
    border-style: none;
    margin-right: 0;
    flex-grow: 1;
    &:hover {
      color: gray;
    }
  }
  .sign-up {
    border-radius: 0px;
    border-style: none;
    margin-right: 0;
  }
  .user-drawer.live-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding-left: 24px;
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  .user-drawer {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    padding-left: 24px;
    &:hover {
      color: gray;
    }
  }
}
.join-event {
  margin-left: 1em !important;
}
.nav-links-mobile.live-btn {
  display: flex;
  //min-height: #{$mobileHeaderHeight};
  //border-top: 1px solid;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  @media only screen and (min-width: 601px) {
    display: none;
  }
  .donate-btn2.live-btn {
    /*
    border-radius: 0px;
    margin-right: 0;
    padding: 0 3em;
    height: 100%;
    width: 100%; 
    */
    border-radius: 5px;
    margin-right: 0;
    padding: 0.5em 2.1em;
    display: flex;
    //height: 100%;
    @media only screen and (min-width: 601px) {
      display: none;
    }
  }
  .totalButton2.live-btn {
    color: white;
    border: 0.5px solid;
    border: 0;
    border-radius: 0;
    border-color: white;
    border-style: none;
    height: 100%;
    width: 100%;
    &:hover {
      color: gray;
    }
  }
  .user-drawer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    &:hover {
      color: gray;
    }
  }
}
.donateButton {
  //flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
}
.amountWidget {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  @media only screen and (max-width: 600px) {
    max-height: #{$mobileHeaderHeight};
  }
}
.nav-links-mobile {
  .MuiBadge-anchorOriginTopRightRectangle {
    transform: none;
  }
}
.user-drawer-modal-wrapper {
  display: flex;
  align-items: center;
}
.blue-light {
  animation: glow 1500ms infinite ease-in-out;
}
@keyframes glow {
  0% {
    background: #b20000;
    box-shadow: 0px 0px 3px #b20000;
  }
  50% {
    background: #ff0000;
    box-shadow: 0px 0px 40px #ff0000;
  }
  100% {
    background: #b20000;
    box-shadow: 0px 0px 3px #b20000;
  }
}

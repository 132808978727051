.creditcard-icon {
  font-size: 1rem;
  font-weight: 400;
}

.creditcard-type-number {
  font-size: 1rem;
  font-weight: 400;
}

.creditcard-number {
  font-size: 1rem;
  font-weight: bolder;
}

.paddedTextFieldsArea {
  padding: 1rem;
}

.boldSubHeader {
  font-size: 1rem;
  font-weight: bold;
}

.eventName {
  display: inline-block;
  padding: 0 0.3rem;
  font-size: large;
}

.payment {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  text-align: end;
  //min-height: 35px;
}

.payment-time {
  font-size: medium;
}

.payment-last-3{
  font-size: medium;
}

.payment-amount {
  font-size: large;
  font-weight: bolder;
  height: 100%;
  text-align: end;
  min-height: 35px;
}
.account-mgmt-pg {
  @media screen and (max-width: 600px) {
    min-height: 100%;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
}
.accountMgmtContainer {
  position: absolute;
  outline: none;
  background-color: #fff;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #000;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
  padding: 0;
  .btn-close {
    @media screen and (max-width: 900px) {
      top: 1rem;
      right: 1rem;
    }
    @media screen and (max-width: 600px) {
      display: none;
    }
    @media screen and (max-height: 729px) {
      top: 1rem;
      right: 1rem;
    }
  }
  @media screen and (max-width: 900px) {
    width: 70% !important;
  }
  @media screen and (max-width: 600px) {
    overflow: auto;
    width: 100% !important;
    height: 100%;
    .page-header {
      display: none;
    }
  }
  @media screen and (max-height: 729px) {
    overflow: auto;
    height: 100%;
  }
}

.MuiPaper-root.accountMgmtWrapper {
  flex-grow: 1;
  @media screen and (min-width: 601px) {
    display: flex;
  }
}

.MuiBox-root.accountMgmtPage {
  margin-bottom: 1rem;
  height: 100%;
}

.MuiTypography-root.accountMgmtPageWrapper {
  flex-grow: 1;
  height: 100%;
}

.edit-profile-content {
  overflow-y: auto;
  @media screen and (min-width: 601px) {
    max-height: 650px;
  }
}

.MuiTabs-scrollable {
  @media screen and (max-width: 600px) {
    margin-top: 50px;
  }
}

.edit-profile-text-field {
  position: relative;
}
.btn-edit-form-field {
  position: absolute;
}

.mobile-admin-close-btn {
  @media screen and (min-width: 601px) {
    display: none;
  }
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.payment-card-col {
  font-size: 1rem;
  font-weight: bold;
}
.payment-date-col {
  font-size: 1rem;
  font-weight: bold;
}
.payment-donation-col {
  font-size: 1rem;
  font-weight: bold;
}

.payment-accordion-summary {
  background-color: #f1f1f1;
}

.payments-tab-scrollable-area {
  padding-top: 0.5rem;
  max-height: 650px;
  overflow: auto;
}

.MuiGrid-root.sequinPage{
  //height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.MuiGrid-root.sequinPage{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.MuiGrid-root.sequinsWrapper{
  //align-items: center;
  flex-grow: 1;
  //height: 80%;
}

.account-mgm-sequin-view {
  position: absolute;
  top: calc(-11rem * .3); //-3.5rem
  transform: rotate(15deg);
  svg {
    height: 11rem;
    width: 11rem;
  }
  @media screen and (min-width: 601px) and (max-width: 960px) {
    top: calc(-7rem * .3);
    svg {
      height: 7rem;
      width: 7rem;
    }
  }
  @media screen and (min-width: 0) and (max-width: 600px) {
    top: calc(-9rem * .3);
    svg {
      height: 9rem;
      width: 9rem;
    }
  }
}

.sequin-name {
  display: block;
  font-size: 1.2em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bolder;
}

.sequin-date{
  display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.sequin-content {
  top: 3.5rem;
}

.sequin-date {
  text-align: center;
}

/*.sequinGridItem{
  flex-grow: 1;
}*/

.react-multi-carousel-list.sequin-carousel-container{
  overflow-y: visible;
  overflow-x: hidden;
  //overflow: visible;
  padding-top: calc(11rem * .31);
  padding-bottom: 1rem;
  @media screen and (min-width: 601px) and (max-width: 960px) {
    padding-top: calc(7rem * .31);
    
  }
  @media screen and (min-width: 0) and (max-width: 600px) {
    padding-top: calc(9rem * .31);
  }
}
.sequin-carousel-item{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MuiCard-root.sequinCard {
  border-radius: 5px;
  display: flex;
  padding-top: 7rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  box-shadow: 5px 5px 5px 5px lightgrey;
  align-items: center;

  @media screen and (min-width: 0) and (max-width: 600px) {
    width: 90%;
    max-width: 270px;
    height: 100%;
    max-height: 370px;
  }
  @media screen and (min-width: 601px) and (max-width: 960px) {
    width: 90%;
    max-width: 270px;
    padding-top: 5rem;
    height: 100%;
    max-height: 370px;
  }
  @media screen and (min-width: 961px) and (max-width: 1280px) {
    width: 90%;
    max-width: 270px;
    height: 100%;
    max-height: 370px;
  }
  @media screen and (min-width: 1281px) {
    width: 270px;
    height: 370px;
  }
}

.see-sequin-link-button-sm {
  @media screen and (min-width: 961px) {
    display: none;
  }
}

.see-sequin-link-button-lg {
  @media screen and (max-width: 960px) {
    display: none;
  }
}

.MuiGrid-root.sequin-grid-wrap{
  align-items: center;
  margin-top: 2.5rem;
  position: relative;
}

.sequin-left-arrow{
  position: absolute;
  left: 0.5rem;
  z-index: 1;
  color: #9e9e9e;
}

.sequin-right-arrow{
  position: absolute;
  right: 0.5rem;
  z-index: 1;
  color: #9e9e9e;
}

.credit-accordion-container {
  width: 100%;
}

.credit-card-scrollable-wrapper {
  padding-top: 0.5rem;
  overflow: auto;
  max-height: 650px;
  overflow: auto;
}

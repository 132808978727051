// Checkin Module

.MuiContainer-root.checkin_outer_container {
  /*
  @include module_container;
  max-height: 80%;
  overflow: auto;
  max-width: 40%;
  @media screen and (max-width: 800px) {

    }
    @media screen and (max-width: 600px) {
      max-width: 100%;
    }
  */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  height: calc(100% - 64px);
  max-width: 40%;
  @media screen and (max-width: 600px) {
    height: calc(100% - 56px);
    max-width: 100%;
  }
}
/*
.checkout_wrapper.right {
  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.checkout_wrapper.left {
  @media screen and (max-width: 1200px) {
    flex-basis: 100%;
    max-width: 100%;
    border-radius: 4px 4px 4px 4px;
  }
}
*/
.cc-form-grid-container {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;

  &::-webkit-scrollbar {
    -webkit-overflow-scrolling: auto;
    -webkit-appearance: none;
    width: 1rem !important;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.5rem;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: lightgray;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.checkin-grid-container.MuiGrid-container {
  overflow: auto;
  min-height: 70%;
}

.MuiGrid-container.checkin_container {
  height: 100%;
  flex-wrap: nowrap;
}

.checkin_header {
  margin-bottom: 1rem;
}

.checkin_button_container {
  //@include module_button_container;
  margin-top: 1rem;
  flex-grow: 1;
  align-items: flex-end;
}

.cc_edit {
  padding-left: 20px;
}

.MuiGrid-container.cc_display {
  width: auto;
  padding-left: 15px;
}

.verifyErrorMessage{
  color: #BD1C3D;
}

.form-page {
  padding: 1.5rem;
  position: relative;
}

.sequin-form-page {
  padding: 1.5rem;
  position: relative;
}

.sequin_form_container {
  @include module_container;
}

.page-header {
  margin-top: 0;
  margin-bottom: -0.5rem;
  display: flex;
  justify-content: space-between;

  h2 {
    margin: 0 0 1rem;
  }

  h5 {
    margin: 0.25rem 0 2rem;
    opacity: 0.6;
  }

  .divider {
    width: 2.5rem;
    display: block;
    border-bottom: 5px solid black;
    margin-bottom: 0;
  }

  .login-form & {
    margin-bottom: 1rem;
  }
}

.formgroup-header {
  margin-bottom: 0;
}

.formgroup-desc {
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
}

.MuiPaper-root.legal-paper {
  overflow: auto;
  height: 20rem;
  padding: 0 1rem;
  line-height: 2;
  background-color: #efefef;
}

.link-button {
  padding: 1rem 0;
  margin-right: 1rem;
  font-weight: bold;
}

.event-info {
  margin-bottom: 0;

  p {
    margin-bottom: 1.5rem;
  }
}

.event-header {
  margin-bottom: 0;
}

.event-description {
  margin-top: 0.25rem;
  margin-bottom: 2rem;
}

.icon-group {
  margin-bottom: 2rem;
  h3 {
    margin: 0;
  }
  p {
    margin: 0.5rem 0;
  }
  .icon-group-icon {
    color: #666;
  }
}

.form-actions {
  padding-top: 1.5rem;
}

.checkbox {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  .MuiFormControlLabel-root {
    align-items: flex-start;
  }

  .sub-header {
    display: block;
    margin-top: 0.5rem;
  }
}

.MuiFormLabel-root.MuiInputLabel-root {
  font-size: 0.85rem;
}

.edit-form {
  margin-bottom: 2rem;
}

.edit-btn {
  color: #1874dc;
  float: right;
  font-weight: 700;
}

.event-cost {
  text-align: right;
}

.event-total {
  font-size: 1.25rem;
  font-weight: 900;
}

.form-line {
  border: 2px solid #333;
  width: 100%;
  margin: 0;
  padding: 0;
}

.entry-line {
  margin-top: 0.5rem;
  display: block;

  & + .entry-line {
    margin-top: 0;
  }
}

.btn-edit-form-field {
  position: absolute;
  right: 2rem;
}

.btn-cancel {
  margin-right: 0.5rem;
}


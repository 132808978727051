/* UI Layer */
$minDesktopHeadersHeight: 64px;
$minMobileHeaderHeight: 56px;

html, body{
  height:100%;
}

.ui-layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.ui-layer-live {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.live-nav-bar-header {
  @media only screen and (min-width: 601px) {
    min-height: #{$minDesktopHeadersHeight};
  }
  @media only screen and (max-width: 600px) {
    min-height: #{$minMobileHeaderHeight};
  }
}

.main-nav-bar-header {
  min-height: #{$minDesktopHeadersHeight};
  @media only screen and (max-width: 600px) {
    min-height: #{$minMobileHeaderHeight};
  }
}

.darken {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;

  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-button {
  pointer-events: all;

  &.hidden {
    display: none;
  }
}
.ui-controls {
  .skip-button {
    @media screen and (max-width: 600px) {
      width: 36px;
      height: 36px;
    }
  }
}
.loading-screen {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(26, 26, 29);
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-text {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #fff;
}

.ui-controls {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 1.5rem;
  pointer-events: none;

  @media only screen and (max-width: 600px) {
    padding: 1rem;
  }

  &.nav {
    align-items: center;
  }

  &.full {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .middle {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
    }

    .right {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.skip-button {
  pointer-events: all;
}

#loadingScreen {
  position: absolute;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 2rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
  flex-direction: column;
  justify-content: center;
  z-index: 9999;

  h3 {
    margin: auto;
    padding: 10px;
  }
}

.forest-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -5;
}

.forest-canvas {
  flex: 1;
  display: block;
}

.social-share {
  margin: 0px;
  top: auto;
  right: 2rem;
  bottom: 2rem;
  left: auto;
  position: fixed;
  .MuiIconButton-root {
    background-color: white;
  }
  .MuiIconButton-root:hover {
    background-color: white;
  }
  li button svg{
    fill: rgba(0, 0, 0, 0.87);
  }
}

// this made other buttons gray too
/*
.MuiButtonBase-root .MuiSvgIcon-root{
  fill: #343434 !important;
}*/

.MuiPopover-root.share-menu{
  .MuiButtonBase-root .MuiSvgIcon-root{
    fill: #343434;
  }
}
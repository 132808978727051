@mixin module_wrapper {
  border: 1px solid #ddd;
  padding: 1.5rem;
}

@mixin module_container {
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
}

@mixin module_title {
  font-weight: bold;
  font-size: 18px;
}

@mixin module_description {
  font-size: 0.75rem;
}

@mixin module_button_container {
  margin-top: auto;
}

@mixin module_scroll {
  &::-webkit-scrollbar {
    width: 1rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.5rem;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: lightgray;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
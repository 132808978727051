// Checkout Module
$desktopHeadersHeight: 64px;
$leaveYourMarkPadding: 0.4rem;

.MuiGrid-root.checkout_grid.MuiGrid-container.MuiGrid-wrap-xs-nowrap {
  max-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.rsvp_wrapper {
  @include module_wrapper;
  max-height: 520px;

  .cc-form-grid-container {
    height: 380px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .MuiGrid-spacing-xs-2 {
    height: 380px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      -webkit-overflow-scrolling: auto;
      -webkit-appearance: none;
      width: 1rem !important;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0.5rem;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.5rem;
      background-color: lightgray;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &.left {
    overflow: auto;
    border-radius: 4px 0px 0px 4px;
    flex-basis: 66%;
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      border-radius: 4px;
    }
  }
  &.right {
    border-radius: 0px 4px 4px 0px;
    flex-basis: 33%;
    @media screen and (max-width: 800px) {
      display: none;
      flex-basis: 0%;
    }
  }
  &.shaded {
    background-color: red;
    background: url(https://sjexperience-media.s3.amazonaws.com/images/ui/right-bar.jpg)
      no-repeat center center;
  }
}

.checkout_wrapper {
  @include module_wrapper;
  &.left {
    display: flex;
    overflow: auto;
    border-radius: 4px 0px 0px 4px;
    flex-basis: 67%;
    max-width: 90%;
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      border-radius: 4px;
    }
    @media screen and (max-width: 600px) {
      height: 100%;
      position: relative;
      max-width: 100%;
    }
    @include module_scroll;
  }
  &.right {
    border-radius: 0px 4px 4px 0px;
    flex-basis: 33%;
    @media screen and (max-width: 1200px) {
      display: none;
      flex-basis: 0%;
    }
  }
  &.shaded {
    background-color: red;
    background: url(https://sjexperience-media.s3.amazonaws.com/images/ui/right-bar.jpg)
      no-repeat center center;
    background-size: cover;
  }
}

.checkout_header {
  margin-bottom: 1rem;
}

.MuiContainer-root.checkout_container.desktop {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-height: 100%;
  min-height: 50%;
  z-index: 2;
  height: max-content;

  @media screen and (max-width: 600px) {
    overflow: auto;
    max-width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.MuiGrid-root.live-event-ui.MuiGrid-container {
  //height: 100%;
  @media screen and (min-width: 0) and (max-width: 600px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.MuiContainer-root.checkout_container.mobile {
  overflow: auto;
  max-width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media screen and (min-width: 961px) {
    display: none;
  }
  @media screen and (min-height: 0) and (max-height: 500px) and (min-width: 601px) {
    display: none;
  }
  @media screen and (min-width: 601px) {
    display: none;
  }
}

.checkout-add-button {
  align-items: stretch;
  display: flex;
  padding-left: 5px;
}

.checkout_title {
  @include module_title;
  padding-bottom: 10px;
  color: #212121;
  text-align: center;
}

.ms_checkout_link {
  @include module_title;
  padding-bottom: 10px;
  color: #1976d2;
}

.checkout_description {
  @include module_description;
}

.checkout_donate_msg {
  border-top-style: solid;
  border-top-width: 2px;
  border-top-color: #707070;
  color: #707070;
  padding-top: 15px;
  padding-bottom: 25px;
  &.thanks {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #707070;
  }
}

.checkout-fade {
  display: flex;
  min-width: 60%;
  @media screen and (max-width: 600px) {
    height: 100%;
  }
}
.live-event-ui-grid-desktop {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  /*
  @media screen and (max-width: 600px) {
    display: none;
  }
  */
}
/*
.checkout-grid-wrap {
  position: relative;
}
*/
.checkout-grid-wrap.MuiGrid-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @include module_scroll;
}

.ms-checkout-grid-wrap.MuiGrid-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  //overflow-x: hidden;
  @include module_scroll;
}

.sequin-form-main-content {
  overflow: auto;
  @include module_scroll;
}
.sequin-form-grid-item {
  padding: #{$leaveYourMarkPadding};
}
.sequin-review-container {
  overflow-y: auto;
  overflow-x: hidden;
}

.sequin-review-main-content-container {
  overflow: auto;
  @include module_scroll;
}

.sequin-form-submit-grid-item {
  flex-direction: row;
  justify-content: flex-end;
  padding-right: #{$leaveYourMarkPadding};
  //@media screen and (max-width: 600px) {
  flex-direction: column;
  //}
}

.MuiGrid-root.sequin-form-submit-grid-item {
  @media screen and (min-width: 601px) {
    width: fit-content;
  }
}

.MuiButton-root.leave-mark-submit-button {
  padding: 0.5em 2.1em;
}
.expand-read-wrapper {
  display: flex;
  flex-direction: row;
  color: #1874dc;
  padding-top: 0.2rem;
  align-items: center;
  @media screen and (min-width: 601px) {
    display: none;
  }
}
.MuiCollapse-container.collapse-disclaimer {
  position: relative;
  min-height: 30px;
  height: 30px;
  .MuiCollapse-entered {
    min-height: 30px;
    height: auto;
  }
  @media screen and (min-width: 601px) {
    height: auto;
  }
}

.MuiCollapse-container.collapse-disclaimer.MuiCollapse-entered {
  min-height: 30px;
  height: auto;
}
.MuiCollapse-container.collapse-disclaimer:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 90%
  );
  width: 100%;
  height: 0.6rem;
  @media screen and (min-width: 601px) {
    display: none;
  }
}
.MuiCollapse-container.collapse-disclaimer.MuiCollapse-entered:after {
  display: none;
}

.read-expand-text {
  padding-right: 0.5rem;
  font-size: small;
  font-weight: 650;
}
.MuiAvatar-root.MuiAvatar-circle.expand-avatar {
  border: 1px solid;
  color: #1874dc;
  background-color: transparent;
  width: 1rem;
  height: 1rem;
}
.MuiSvgIcon-root.expand-icon {
  width: 1rem;
  height: 1rem;
}

.MuiGrid-root.checkout-cart {
  overflow-y: auto;
  overflow-x: hidden;
}
.checkout-cart {
  @include module_scroll;
}
.billing-address-container.MuiGrid-container {
  overflow: auto;
  @include module_scroll;
  margin-bottom: 0.5rem;
}
.checkout-page-header-grid-item {
  flex-grow: 0;
}
.error-message-grid-container.MuiGrid-container {
  margin-bottom: 0.5rem;
  flex-basis: 0;
}

.MuiGrid-root.checkout-cart.desktop {
  overflow-y: auto;
  overflow-x: hidden;
  @include module_scroll;
}

.MuiGrid-root.checkout-confirmation-container {
  overflow-y: auto;
  overflow-x: hidden;
  @include module_scroll;
}

.cc-form-grid-container {
  overflow: auto;
  @include module_scroll;
}

.review-leave-your-mark-buttons-grid-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 0.5rem;
  justify-content: space-between;
}

.back-to-edit-grid-item {
  @media screen and (max-width: 600px) {
    min-width: 100%;
  }
  @media screen and (min-width: 601px) {
    max-width: 65%;
  }
  display: flex;
  justify-content: flex-start;
  //height: 100%;
  padding-bottom: 0.5rem;
}

.build-grid-item {
  //display: flex;
  //justify-content: flex-end;
  @media screen and (max-width: 600px) {
    min-width: 100%;
  }
  @media screen and (min-width: 601px) {
    max-width: 35%;
  }
  padding-bottom: 0.5rem;
}

.build-progress {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.checkout_button_container {
  padding: 0.3rem;
  flex-grow: 1;
  align-items: flex-end;
}

.back-button-grid-item {
  padding-bottom: 0.3rem;
}

.sequin-form-circular-progress {
  display: flex;
  justify-content: center;
}

.MuiGrid-root.sequin-form-submit-button-wrapper.MuiGrid-container {
  flex-grow: 1;
  justify-content: flex-end;
}

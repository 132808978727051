#root {
}

body {
  overflow: hidden;
}

@import "src/scss/_page-background.scss";
@import "src/scss/_page-forest.scss";
@import "src/scss/_page-landing.scss";
@import "src/scss/_page-live.scss";

@import "src/scss/components/_site-header.scss";
@import "src/scss/components/_layer-ui.scss";
@import "src/scss/components/_animations.scss";
@import "src/scss/components/_chat.scss";

@import "src/scss/components/_module-base.scss";
@import "src/scss/components/_module-formpage.scss";
@import "src/scss/components/_module-checkout.scss";
@import "src/scss/components/_module-checkin.scss";
@import "src/scss/components/_module-login.scss";
@import "src/scss/components/_module-register.scss";
@import "src/scss/components/_module-rsvp.scss";
@import "src/scss/components/_module-polling.scss";
@import "src/scss/components/_module-countdowntimer.scss";
@import "src/scss/components/_module-sequininfo.scss";
@import "src/scss/components/_module-verticaltabs.scss";
@import "src/scss/components/_module-reactplayer.scss";

@import "src/scss/components/_donate-popup.scss";
@import "src/scss/components/_accountManagement.scss";
@import "src/scss/components/_progress-bar.scss";
@import "src/scss/components/_page-loader.scss";
@import "src/scss/components/_forgotPassword.scss";

@import "src/scss/components/_module-eventportal.scss";

@import "src/scss/components/_video-player.scss";

.page-wrapper {
  margin-top: 3rem;
}

// fixing a material UI issue (Contained primary button text color doesn't update after becoming enabled https://github.com/mui-org/material-ui/issues/26251)
.ui-layer {
  .MuiButton-root {
    transition: color 250ms;
    //background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  }
}

// close btn at the top right of the module
button.btn-close {
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  z-index: 1;
}

// close btn at the top left of the module
button.left-btn-close {
  position: absolute;
  top: -1.5rem;
  left: -1.5rem;
}

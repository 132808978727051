$minDesktopHeadersHeight: 64px;
$minMobileHeaderHeight: 56px;
// Login Module
.login_wrapper {
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow: auto;
  @include module_scroll;
}

.MuiContainer-root.login_container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 35%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media screen and (max-width: 800px) {
    max-width:70%;
  }
  @media screen and (max-width: 600px) {
    max-width:100%;
  }
}

.MuiPaper-root.login_header {
  display: flex;
  align-items: flex-end;
  padding: 1.5rem 1.5rem 0rem 1.5rem;
  color: #ffffff;
  background-color: #848484;
  height: 30%;
  flex-basis: auto;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.MuiPaper-root.login_form {
  padding: 1.5rem;
  position: relative;
  height: 70%;
  flex-basis: auto;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  overflow: auto;
  @include module_scroll;
}

.login_form_container {
  height: 100%;
  overflow: auto;
  @include module_scroll;
}

.login_title {
  font-weight: 800;
}

.login_error {
  height: 19px;
}

.login_fields {
  padding: 8px 8px 8px 8px;
  align-self: flex-start;
  overflow: auto;
  @include module_scroll;
}

.login_field {
  padding-bottom: 8px;
}

.login_form_bottom {
  padding: 0px 8px 0px 8px;
  align-self: flex-end;
  align-items:center;
}

.login_container_wrapper{
  position: relative;
  width: 100%;
  height: calc(100% - #{$minDesktopHeadersHeight});
  @media screen and (max-width: 600px) {
    height: calc(100% - #{$minMobileHeaderHeight});
  }
}
.event-not-started-wrapper{
    display: flex;
    justify-content: center;
}

.event-not-started-paper{
    display: flex;
    justify-content: center;
    overflow: auto;
    @include module_scroll;
}

.event-not-started-grid-container{
    display: flex;
    overflow: auto;
    @include module_scroll;
}

.event-not-started-text-grid-item{
    display: flex;
    font-size: large;
    font-weight: bolder;
    padding: 2rem;
}
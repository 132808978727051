$desktopHeadersHeight: 64px;
$mobileMainHeadersHeight: 56px;

.renderResetFormGrid.MuiGrid-container{
  max-height: 100%;
}

.change-password-page-wrapper.MuiContainer-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - #{$desktopHeadersHeight});
  padding: 0.5rem;
  @media screen and (max-width: 600px) {
    height: calc(100% - #{$mobileMainHeadersHeight});
  }
}

.forgot-password-link-button {
  margin-right: 1rem;
  font-weight: bold;
  float: right;
}

.forgot-password-instruction {
  font-size: 1rem;
  line-height: 2rem;
  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
    line-height: 1.3rem;
  }
}

.forgot-password-next-button-grid.MuiGrid-container {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-basis: 0;
  @media screen and (min-width: 601px) {
    align-items: flex-end;
  }
}

.forgot_password_grid_container.MuiGrid-container {
  display: flex;
  flex-direction: row;
  max-height: 100%;
}

.forgot_password_content.MuiGrid-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.page-header.forgotpasswordheader{
  margin-bottom: 1rem;
}

.renderResetMainContentGrid.MuiGrid-container{
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include module_scroll;
}

.forgot_password_verification_grid_container.MuiGrid-container{
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.forgot_password_main_grid_container{
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  @include module_scroll;
}

.MuiGrid-root.forgot_password_reset_title {
  flex-basis: 0;
}

.MuiGrid-root.renderResetMainContentGrid{
  flex-basis: 1;
}

.MuiGrid-root.login_error{
  margin-bottom: 1rem;
}

.success-grid-container{
  max-height: 100%;
}

.forgot_password_success_grid_container.MuiGrid-container{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  
}

.MuiGrid-root.forgot_password_success_main_container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
  flex-basis: 1;
  @include module_scroll;
}

.MuiGrid-root.forgot-password-success-instruction-grid-item{
  margin-bottom: 1rem;
}

.MuiGrid-root.success-grid-item{
  flex-basis: 0;
}
/* Live Page */

$headersHeight: 64px;
$mobileBannerHeight: 40px;
$mobileHeadersHeight: 121px;

@mixin ui-event {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: calc(100vw);
  height: calc(100vh);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.live-event-ui-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
  flex-grow: 1;
  overflow: auto;
  @media screen and (min-width: 601px) {
    display: none;
  }
}

.checkout-mobile {
  z-index: 3;
  display: flex;
  flex-direction: column;
}

.checkout-mobile-show-max {
  max-height: 100%;
  min-height: 70%;
}

.live-event-window-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 45%;
  position: relative;
  flex-grow: 1;

  .live-event-player {
    width: 100%;
    height: 90%;
    @media screen and (max-width: 600px) {
      height: 100%;
    }

    position: relative;
  }
}

.live-event-window-min {
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.live-event-chat-mobile {
  display: flex;
  background: #ddd;
  justify-content: center;
  align-items: center;
  min-height: 45%;
  position: relative;
  flex-grow: 1;

  .chat__header {
    padding: 5px 5px;
    align-items: center;
  }
}
.chat__details {
  .chat__user {
    width: calc(100% - 67px);
  }
}
.live-event-chat.checkout {
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.MuiSvgIcon-root.chat-toggle-sound-on-icon {
  fill: #1976d2;
}

.MuiSvgIcon-root.chat-toggle-sound-off-icon {
  fill: gray;
}
.MuiSvgIcon-root.chat-sound-off-icon {
  color: gray;
}
.MuiSvgIcon-root.chat-sound-on-icon {
  color: gray;
}
.extra-chat-options {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.live-event-banner-mobile {
  display: flex;
  height: #{$mobileBannerHeight};
  position: relative;
  flex-direction: column;
  padding: 0.5rem;
  justify-content: center;

  font-size: 1rem;
  font-weight: bolder;
  letter-spacing: -0.5px;
  color: white;

  .event-name {
    margin: 0 0 0.5rem;
  }
  @media screen and (min-width: 601px) {
    display: none;
  }
}

.live-event-ui {
  display: flex;
  height: 100%;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
    flex-grow: 1;
    overflow: auto;
  }
  /*
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
    flex-grow: 1;
    overflow: auto;
  }
  */
}

.live-event-window {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 600px) {
    height: 50%;
    position: relative;
    max-height: 100%;
    //min-height: 70%;
  }

  .live-event-player {
    width: 100%;
    height: 100%;
    @media screen and (max-width: 600px) {
      //height: 80%;
      position: static;
    }
    position: relative;
  }

  .live-event-banner {
    width: 100%;
    height: #{$headersHeight};
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 1.25rem;
    font-weight: bolder;
    letter-spacing: -0.5px;
    color: white;
    padding: 1rem;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}

.live-event-chat {
  display: flex;
  background: #ddd;
  justify-content: center;
  align-items: center;

  /* To be replaced when chat is added */
  // font-size: 3rem;
  // font-weight: bolder;
  // letter-spacing: -2px;

  @media screen and (max-width: 600px) {
    height: 50%;
    display: flex;
    background: #ddd;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-grow: 1;

    .chat__header {
      padding: 5px 5px;
      align-items: center;
    }
  }
}

.ui-event-modal {
  @include ui-event;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.ui-event {
  @include ui-event;
}

.confetti {
  z-index: 5;
}

.event-info {
  padding: 0.5rem;
  background-color: white;
  border-radius: 5px;
  color: black;
  display: flex;
  justify-content: center;

  .event-readout {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      justify-content: center;

      svg {
        padding: 0.2rem;
      }
    }
  }

  .event-partcipants {
    padding: 0.5rem;
    display: flex;
    align-items: center;

    @media screen and (max-width: 600px) {
      font-size: 1rem;
      padding: 0 0 0.5rem;
    }
  }

  .event-time {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    margin-right: 2rem;

    @media screen and (max-width: 600px) {
      font-size: 1rem;
      padding: 0;
    }
  }

  .event-icon {
    margin-right: 0.25rem;
  }
}
